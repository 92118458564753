<script type="text/ecmascript-6">

import DashboardLayout from '@/layouts/Dashboard.vue'
import orderReservationsService from '@/services/orderReservations'

export default {
    
    components: {DashboardLayout},
    data() {

        return {

            orderReservationReclaims: [],
            merchantFilter: ''
        }
    },
    methods: {

        async loadOrderReservationReclaims() {

            let response = await orderReservationsService.listReclaimsWaiting()
            this.orderReservationReclaims = response.data
        },
    },
    created() {

        this.loadOrderReservationReclaims()
    },
    computed: {
        reclaimsCleaned() {

            let reclaims = []

            this.orderReservationReclaims.forEach(reclaim => {

                if (this.merchantFilter !== '' && reclaim.merchantName !== this.merchantFilter) return

                let index = reclaims.findIndex(r => r.orderPublicId === reclaim.orderPublicId)
                if (index === -1) {

                    reclaims.push({
                        orderPublicId: reclaim.orderPublicId,
                        nbr: 1,
                        maxCreatedAt: reclaim.created_at,
                        lastReason: reclaim.reason,
                        merchantName: reclaim.merchantName
                    })
                }
                else {

                    reclaims[index].nbr++
                    if (reclaim.created_at > reclaims[index].maxCreatedAt) {

                        reclaims[index].maxCreatedAt = reclaim.created_at
                        reclaims[index].lastReason = reclaim.reason
                    }
                }
            })

            return reclaims.sort((a, b) => b.maxCreatedAt - a.maxCreatedAt)
        },
        merchantsFilter() {

            if (this.orderReservationReclaims == undefined) return []

            let merchants = []
            
            this.orderReservationReclaims.forEach(reclaim => {

                if (reclaim.merchantName != undefined && reclaim.merchantName != '' && merchants.indexOf(reclaim.merchantName) === -1) {

                    merchants.push(reclaim.merchantName)
                }
            })

            return merchants
        }
    }
}
</script>
<template>
    <dashboard-layout>
        <h2>Order Reservation Reclaims</h2>
        <div>
            <label>Merchant</label>
            <select v-model="merchantFilter">
                <option value="">All</option>
                <option v-for="merchant in merchantsFilter" :key="merchant" :value="merchant">{{ merchant }}</option>
            </select>
        </div>
        <table class="table table-striped">
            <thead>
                <th></th>
                <th>Order ID</th>
                <th>Merchant</th>
                <th>Max Created at</th>
                <th>Last reason</th>
                <th>Nbr</th>
            </thead>
            <tbody>
                <tr v-for="orderReservationReclaim in reclaimsCleaned" :key="orderReservationReclaim.jobId">
                    <td><router-link :to="{name: 'orderReservationReclaimsByOrder', params: {'orderId' : orderReservationReclaim.orderPublicId}}"><i class="bi bi-eye"></i></router-link></td>
                    <td>{{ orderReservationReclaim.orderPublicId }}</td>
                    <td>{{ orderReservationReclaim.merchantName }}</td>
                    <td>{{ orderReservationReclaim.maxCreatedAt * 1000 | date }}</td>
                    <td>{{ orderReservationReclaim.lastReason }}</td>
                    <td>{{ orderReservationReclaim.nbr }}</td>
                </tr>
            </tbody>
        </table>
    </dashboard-layout>
</template>
