<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import orderServices from '../services/order'
import mediaMixin from '@/mixins/media.js'

export default {
    
    components: {DashboardLayout},
    mixins: [mediaMixin],
    data() {

        return {

            order: null,
            userPrice: 0,
            logisticExclTax: 0,
            comVarExclTax: 0,
            comFixExclTax : 3.90,
            comExclTaxTotal: 0,
            totalPrice: 0,
            comPercentage: 0,
            comInclTaxTotal: 0, 
            logisticInclTax: 0,
            priceCalculated:false,
            errorMessage: ""
        }
    },
    methods: {

        async loadOrderDetails() {

            let orderId = this.$route.params.orderId
            let response = await orderServices.getOrderDetails(orderId)
            this.order = response.data
            this.userPrice = this.order.price
            this.logisticExclTax = this.order.logistic_excl_tax_price,
            this.comExclTaxTotal = this.order.commission_excl_tax_price,
            this.totalPrice = this.order.total_price
            this.comPercentage = this.getCommissionRatio(this.order)
            this.comInclTaxTotal = this.order.commission_incl_tax_price
            this.logisticInclTax = this.order.logistic_incl_tax_price
        },
        getCommissionRatio(order) {

            return (order.commission_excl_tax_price / order.price * 100).toFixed(2)
        },
        async updatePrices(toggle) {

            this.errorMessage = ''

            if (toggle) {

                if (parseInt(this.logisticExclTax) > parseInt(this.totalPrice) || parseInt(this.comExclTaxTotal) > parseInt(this.totalPrice)) {

                    this.errorMessage = 'La logistique ou la commission est trop élevée'
                }
                else {

                    this.errorMessage = ''
                    this.userPrice = this.totalPrice - this.logisticExclTax - this.comExclTaxTotal
                    this.comPercentage = parseFloat(this.comExclTaxTotal / this.totalPrice * 100).toFixed(2)
                }
            }

            this.priceCalculated = toggle
        },
        async submitNewPrice() {

            let response = await orderServices.updatePrice(this.order.id, this.userPrice, this.logisticExclTax, this.comExclTaxTotal, this.totalPrice)

            if (response.data) {

                this.errorMessage = ""
                this.loadOrderDetails()
            }
            else {
                this.errorMessage = 'Une erreur est survenue'
            }
        }
    },
    created() {

        this.loadOrderDetails()
    },
    computed: {

        isImmediatStore() {

            return this.order && this.order.coupon_type == 'IMMEDIAT_STORE'
        }
    }
}
</script>

<template>
    <dashboard-layout>
        <div v-if="order != undefined">
            <h2>{{order.public_id}}</h2>
            <h4 class="mt-5">Order detail</h4>
            <table class="table table-bordered col-md-6">
                <tbody>
                    <tr>
                        <th>ID</th>
                        <td class="align-middle">{{order.public_id}}</td>
                    </tr>
                    <tr>
                        <th>Merchant</th>
                        <td class="align-middle"><img width="50px" :src="getMerchantAsset(order.merchant.id, order.merchant.asset_logo_name)" /></td>
                    </tr>
                    <tr>
                        <th>Date</th>
                        <td class="align-middle">{{order.created_at | date}}</td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td class="align-middle">{{order.status.name}}</td>
                    </tr>
                    <tr>
                        <th>Reseller</th>
                        <td class="align-middle">{{order.reseller.name}}</td>
                    </tr>
                    <tr>
                        <th>Type</th>
                        <td class="align-middle">{{order.coupon_type}}</td>
                    </tr>
                </tbody>
            </table>
            <h4 class="mt-5">Order prices</h4>
            <table class="table table-bordered col-md-8">
                <thead>
                    <th></th>
                    <th class="text-center align-top">
                        Before
                    </th>
                    <th class="d-flex flex-column">
                        <div class="m-auto my-3">
                            After
                        </div>
                        <div class="text-center">
                            <button class="btn btn-info mr-3" @click="updatePrices(true)">Calculate</button>
                            <button :disabled="!priceCalculated || order.status.id != 6" @click="submitNewPrice()" class="btn btn-warning text-white"><span v-if="isImmediatStore">Submit & validate</span><span v-else>Submit</span></button>
                            <p v-if="errorMessage != ''" class="text-danger">{{ errorMessage }}</p>
                        </div> 
                    </th>
                    <th v-if="isImmediatStore">Store's counter offer</th>
                </thead>
                <tbody>
                    <tr>
                        <th>User Price</th>
                        <td class="align-middle text-right">{{order.price * 100 | moneyInteger}}</td>
                        <td class="text-right"><input disabled class="text-right" type="number" step=".01" v-model="userPrice" /></td>
                        <td v-if="isImmediatStore" class="text-right">{{ order.counter_offer.to_price }}</td> 
                    </tr>
                    <tr>
                        <th>Logistic</th>
                        <td class="align-middle text-right">{{order.logistic_excl_tax_price * 100 | moneyInteger}}</td>
                        <td class="text-right"><input @click="updatePrices(false)" class="text-right" type="number" step=".01" v-model="logisticExclTax" /></td>
                        <td v-if="isImmediatStore" class="text-right">{{ order.counter_offer.to_logistic_excl_tax_price }}</td>
                    </tr>
                    <tr>
                        <th>Commission</th>
                        <td class="align-middle text-right">{{order.commission_excl_tax_price * 100 | moneyInteger}}</td>
                        <td class="text-right"><input @click="updatePrices(false)" class="text-right" type="number" step=".01" v-model="comExclTaxTotal" /></td>
                        <td v-if="isImmediatStore" class="text-right">{{ order.counter_offer.to_commission_excl_tax_price }}</td>
                    </tr>
                    <tr>
                        <th>Total Price</th>
                        <td class="align-middle text-right">{{order.total_price * 100 | moneyInteger}}</td>
                        <td class="text-right"><input @click="updatePrices(false)" class="text-right" type="number" step=".01" v-model="totalPrice" /></td>
                        <td v-if="isImmediatStore" class="text-right">{{ order.counter_offer.to_total_price }}</td>
                    </tr>
                    <tr>
                        <th>% Commission</th>
                        <td class="align-middle text-right">{{getCommissionRatio(order)}} %</td>
                        <td class="text-right" type="number" step=".01">{{ comPercentage }}</td>
                        <td v-if="isImmediatStore" class="text-right"></td>
                    </tr>
                    <tr>
                        <th>Logistic Incl. Taxes</th>
                        <td class="align-middle text-right">{{order.logistic_incl_tax_price * 100 | moneyInteger}}</td>
                        <td class="text-right" type="number" step=".01"> {{ logisticInclTax }}</td>
                        <td v-if="isImmediatStore" class="text-right"></td>
                    </tr>
                    <tr>
                        <th>Commission Incl. Taxes</th>
                        <td class="align-middle text-right">{{order.commission_incl_tax_price * 100 | moneyInteger}}</td>
                        <td class="text-right" type="number" step=".01">{{ comInclTaxTotal }}</td>
                        <td v-if="isImmediatStore" class="text-righ"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </dashboard-layout>
</template>
